$aligns: ('start', 'center', 'inherit', 'initial', 'unset', 'end', 'justify', 'left', 'right');

$colors: (
  'main': $color-main,
  'secondary': $color-secondary,
  'inherit': inherit,
  'white': $white-100,
  'white-200': $white-200,
  'green-500': $green-500,
  'black-400': $black-400,
  'black-300': $black-300,
  'purple-100': $purple-100,
  'purple-200': $purple-200,
  'purple-400': $purple-400,
  'purple-500': $purple-500,
  'purple-600': $purple-600,
  'dark-gray-300': $dark-gray-300,
  'dark-gray-400': $dark-gray-400,
  'black-100': $black-100,
  'black-200': $black-200,
);

$sizes: (
  '3xs': (
    small: (
      font-size: $font-size-3xs,
      line-height: $line-height-l,
    ),
    large: (
      font-size: $font-size-3xs,
      line-height: $line-height-l,
    ),
  ),
  '2xs': (
    small: (
      font-size: $font-size-mob-3xs,
      line-height: $line-height-l,
    ),
    large: (
      font-size: $font-size-2xs,
      line-height: $line-height-l,
    ),
  ),
  'xs': (
    small: (
      font-size: $font-size-2xs,
      line-height: $line-height-l,
    ),
    large: (
      font-size: $font-size-xs,
      line-height: $line-height-l,
    ),
  ),
  's': (
    small: (
      font-size: $font-size-xs,
      line-height: $line-height-l,
    ),
    large: (
      font-size: $font-size-s,
      line-height: $line-height-l,
    ),
  ),
  'm': (
    small: (
      font-size: $font-size-s,
      line-height: $line-height-xl,
    ),
    large: (
      font-size: $font-size,
      line-height: $line-height-xl,
    ),
  ),
  'l': (
    small: (
      font-size: $font-size,
      line-height: $line-height-xl,
    ),
    large: (
      font-size: $font-size-l,
      line-height: $line-height-xl,
    ),
  ),
);

@mixin fs($small, $large) {
  font-size: map-get($small, font-size);
  line-height: map-get($small, line-height);

  @include mq(lg) {
    font-size: map-get($large, font-size);
    line-height: map-get($large, line-height);
  }
}
