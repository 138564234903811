@mixin ellipsis-multiline($font-size: 1em, $line-height: 1.2, $lines-to-show) {
  display: block; // Fallback for non-webkit
  display: -webkit-box;

  // height: $font-size * $line-height * $lines-to-show;
  max-height: $font-size * $line-height * $lines-to-show; // Fallback for non-webkit
  overflow: hidden;
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
