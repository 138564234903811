@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
@import './variables';
@import './mixins';

.title {
  $block-name: &;

  color: $black-400;
  font-family: $font-family--headings;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: $font-weight-l;

  &--children-tag-color-main {
    span {
      color: $color-main;
    }
  }

  &--children-tag-color-green-500 {
    span {
      color: $green-500;
    }
  }

  &--children-tag-color-green-600 {
    span {
      color: $green-600;
    }
  }

  &--weight-m {
    font-weight: $font-weight-m;
  }

  &--weight-xl {
    font-weight: $font-weight-xl;
  }

  &--is-ellipsis {
    @include ellipsis;
  }

  &--variant-default {
    font-family: $font-family--headings;
  }

  &--variant-expanded {
    font-family: $font-family--headings-expanded;
  }

  @each $key, $size in $sizes {
    &--size-#{$key} {
      @include fs(map-get($size, small), map-get($size, large));

      &#{$block-name} {
        &--ellipsis-rows-two {
          @include ellipsis-rows(map-get($size, small), map-get($size, large), $lines: 2);
        }

        &--ellipsis-rows-three {
          @include ellipsis-rows(map-get($size, small), map-get($size, large), $lines: 3);
        }

        &--ellipsis-rows-fourth {
          @include ellipsis-rows(map-get($size, small), map-get($size, large), $lines: 4);
        }
      }
    }
  }

  @each $align in $aligns {
    &--align-#{$align} {
      text-align: unquote($align);
    }
  }

  @each $key, $color in $colors {
    &--color-#{$key} {
      color: $color;
    }
  }
}
