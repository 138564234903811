$aligns: ('start', 'center', 'inherit', 'initial', 'unset', 'end', 'justify', 'left', 'right');

$colors: (
  'main': $color-main,
  'secondary': $color-secondary,
  'inherit': inherit,
  'white': $white-100,
  'green-500': $green-500,
  'black-400': $black-400,
  'black-300': $black-300,
  'black-200': $black-200,
  'purple-100': $purple-100,
  'purple-200': $purple-200,
  'purple-400': $purple-400,
  'purple-500': $purple-500,
  'purple-600': $purple-600,
);

$sizes: (
  's': (
    small: (
      font-size: $font-size-xs,
      line-height: $line-height-xs,
    ),
    large: (
      font-size: $font-size-xs,
      line-height: $line-height-xs,
    ),
  ),
  'm': (
    small: (
      font-size: $font-size,
      line-height: $line-height-xs,
      letter-spacing: -0.4px,
    ),
    large: (
      font-size: $font-size,
      line-height: $line-height-xs,
    ),
  ),
  'l': (
    small: (
      font-size: $font-size-mob-xs,
      line-height: $line-height-xs,
      letter-spacing: -0.66px,
    ),
    large: (
      font-size: $font-size-l,
      line-height: $line-height-s,
      letter-spacing: -0.48px,
    ),
  ),
  'xl': (
    small: (
      font-size: $font-size-l,
      line-height: $line-height-xs,
      letter-spacing: -0.72px,
    ),
    large: (
      font-size: $font-size-xl,
      line-height: $line-height-s,
      letter-spacing: -0.56px,
    ),
  ),
  '2xl': (
    small: (
      font-size: $font-size-mob-m,
      line-height: $line-height-xs,
      letter-spacing: -0.75px,
    ),
    large: (
      font-size: $font-size-2xl,
      line-height: $line-height-s,
      letter-spacing: -0.64px,
    ),
  ),
  '3xl': (
    small: (
      font-size: $font-size-mob-l,
      line-height: $line-height-s,
      letter-spacing: -0.78px,
    ),
    large: (
      font-size: $font-size-3xl,
      line-height: $line-height-xs,
      letter-spacing: -1.44px,
    ),
  ),
  '4xl': (
    small: (
      font-size: $font-size-mob-xl,
      line-height: $line-height-xs,
      letter-spacing: -0.9px,
    ),
    large: (
      font-size: $font-size-4xl,
      line-height: $line-height-2xs,
      letter-spacing: -1.92px,
    ),
  ),
  '5xl': (
    small: (
      font-size: $font-size-mob-1xl,
      line-height: $line-height-2xs,
      letter-spacing: -1.08px,
    ),
    large: (
      font-size: $font-size-5xl,
      line-height: $line-height-2xs,
      letter-spacing: -2.16px,
    ),
  ),
  '6xl': (
    small: (
      font-size: $font-size-mob-3xl,
      line-height: $line-height-2xs,
      letter-spacing: -1.32px,
    ),
    large: (
      font-size: $font-size-6xl,
      line-height: $line-height-2xs,
      letter-spacing: -2.88px,
    ),
  ),
);
