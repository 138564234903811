@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
@import './mixins';

.text {
  color: $black-400;
  font-family: $font-family;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: $font-weight-s;

  &--weight-s {
    font-weight: $font-weight-s;
  }

  &--weight-m {
    font-weight: $font-weight-m;
  }

  &--weight-l {
    font-weight: $font-weight-l;
  }

  &--weight-xl {
    font-weight: $font-weight-xl;
  }

  @each $key, $size in $sizes {
    &--size-#{$key} {
      @include fs(map-get($size, small), map-get($size, large));
    }
  }

  @each $align in $aligns {
    &--align-#{$align} {
      text-align: unquote($align);
    }
  }

  @each $key, $color in $colors {
    &--color-#{$key} {
      color: $color;
    }
  }

  strong {
    font-family: $font-family--headings;
    font-weight: $font-weight-l;
  }

  a {
    text-decoration: underline;
    color: $purple-400;
    font-weight: 500;
    transition: color $transition-time;

    &:hover {
      color: $green-500;
    }

    &:active {
      color: $purple-400;
    }
  }
}
