@mixin fs($small, $large) {
  font-size: map-get($small, font-size);
  line-height: map-get($small, line-height);
  letter-spacing: map-get($small, letter-spacing);

  @include mq(md) {
    font-size: map-get($large, font-size);
    line-height: map-get($large, line-height);
    letter-spacing: map-get($large, letter-spacing);
  }
}

@mixin ellipsis-rows($small, $large, $lines: 2) {
  @include ellipsis-multiline(
    $font-size: map-get($small, font-size),
    $line-height: map-get($small, line-height),
    $lines-to-show: $lines
  );

  @include mq(lg) {
    @include ellipsis-multiline(
      $font-size: map-get($large, font-size),
      $line-height: map-get($large, line-height),
      $lines-to-show: $lines
    );
  }
}
